import type { DateRange, ReportPayload, Site, SiteReport, SiteReports, SitesUnavailabilities } from '../../types';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { addYears, endOfYear, format, startOfYear, subYears } from 'date-fns';
import _ from 'lodash';

import { downloadBlob } from '../../utils/download-file';

/**
 * Site RTK Query API
 *
 * @see https://redux-toolkit.js.org/rtk-query/overview
 */
export const siteApi = createApi({
  reducerPath: 'siteApi',
  baseQuery: fetchBaseQuery({
    headers: {
      'content-type': 'application/json',
    },
  }),
  tagTypes: ['Sites', 'SiteReport', 'SiteReports', 'SitesByYear', 'forCustomer'],
  endpoints: (builder) => ({
    fetchSites: builder.query<Site[], void>({
      query: () => {
        return {
          url: 'api/mezzanine/v2/sites/for-customer',
          method: 'GET',
        };
      },
      transformResponse: (response: Site[]) => {
        return response.map((site) => ({
          ...site,
          siteId: site.siteId.toString(),
          siteName: _.startCase(_.toLower(site.siteName)),
          unscheduledUnavailabilityCutoffHours: site.unscheduledUnavailabilityCutoffHours || 0,
        }));
      },
      providesTags: ['Sites'],
    }),
    fetchSiteReport: builder.query<SiteReport, string>({
      query: (siteId) => {
        return {
          url: `${REPORT_API}/${siteId}`,
          method: 'GET',
        };
      },
      transformResponse: (response: SiteReport, _, siteId) => {
        return {
          ...response,
          siteId: siteId.toString(),
        };
      },
      providesTags: ['SiteReport'],
    }),
    fetchSiteReports: builder.query<SiteReports, SiteReportsArg>({
      query: (args) => {
        return {
          url: `${REPORT_API}/${args.siteIds[0]}`,
          method: 'GET',
          params: {
            start: format(endOfYear(subYears(new Date(args.year, 0, 1), 1)), 'yyyy-MM-dd'),
            end: format(startOfYear(addYears(new Date(args.year, 0, 1), 1)), 'yyyy-MM-dd'),
            additional_site_ids: args.siteIds.join(','),
          },
        };
      },
      providesTags: ['SiteReports'],
    }),
    fetchPerformanceReports: builder.mutation<Response, ReportPayload[]>({
      query: (arg) => {
        const filename = `${[...new Set(arg.map((_) => _.siteId))].join('_')}.zip`;

        return {
          url: 'api/mezzanine/reports/bundle',
          method: 'POST',
          body: {
            fileName: filename,
            reports: arg,
          },
          responseHandler: async (response) => {
            downloadBlob(await response.blob(), filename);

            return response;
          },
          cache: 'no-cache',
        };
      },
    }),
    fetchUnavailabilityPerDate: builder.query<SitesUnavailabilities[], DateRange>({
      query: (arg) => {
        return {
          url: 'api/henge/unavailability/summary',
          params: {
            from: arg.from,
            to: arg.to,
          },
          method: 'GET',
        };
      },
      transformResponse: (response: SitesUnavailabilities[]) => {
        return [...response].map((site) => ({
          siteId: site.siteId.toString(),
          availabilityRate: site.availabilityRate,
        }));
      },
    }),
  }),
});

const REPORT_API = 'api/proxy/tegami/reports';
export const REPORT_PDF = `${REPORT_API}/pdf/`;

type SiteReportsArg = {
  siteIds: string[];
  year: number;
};

export const {
  useFetchSitesQuery,
  useLazyFetchSitesQuery,
  useFetchSiteReportQuery,
  useLazyFetchSiteReportQuery,
  useFetchSiteReportsQuery,
  useLazyFetchSiteReportsQuery,
  useFetchPerformanceReportsMutation,
  useFetchUnavailabilityPerDateQuery,
  useLazyFetchUnavailabilityPerDateQuery,
} = siteApi;
