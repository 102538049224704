import type { ActionReducerMapBuilder, CreateSliceOptions, PayloadAction } from '@reduxjs/toolkit';
import type { CalendarState } from './slice';

import { calendarApi } from './service';

/** Reducers for calendar slice */
export const reducers: CreateSliceOptions<CalendarState>['reducers'] = {
  setCalendarLoading: (state, action: PayloadAction<boolean>) => {
    state.loading = action.payload;
  },
  setCalendarSelectedFromDate: (state, action: PayloadAction<Date | null>) => {
    state.selectedFromDate = action.payload;
  },
  setCalendarSelectedToDate: (state, action: PayloadAction<Date | null>) => {
    state.selectedToDate = action.payload;
  },
  setCalendarFromDate: (state, action: PayloadAction<string | undefined>) => {
    state.from = action.payload;
  },
  setCalendarToDate: (state, action: PayloadAction<string | undefined>) => {
    state.to = action.payload;
  },
  setCalendarSelectedSite: (state, action: PayloadAction<string | undefined>) => {
    state.selectedSite = action.payload;
  },
} as const;

/**
 * Extra reducers for calendar slice
 *
 * @param builder ActionReducerMapBuilder<CalendarState>
 */
export const extraReducers: CreateSliceOptions<CalendarState>['extraReducers'] = (builder: ActionReducerMapBuilder<CalendarState>) => {
  return builder
    .addMatcher(calendarApi.endpoints.fetchCalendarSummary.matchFulfilled, (state, { payload }) => {
      state.summary = payload;
    })
    .addMatcher(calendarApi.endpoints.fetchCalendarUnavailability.matchFulfilled, (state, { payload }) => {
      state.unavailability = payload;
    })
    .addMatcher(calendarApi.endpoints.postCalendarUnavailability.matchFulfilled, (state, { payload }) => {
      payload.filter(Boolean).forEach((newItem) => {
        const existingIndex = state.unavailability.findIndex((item) => item.id === newItem.id);

        if (existingIndex !== -1) {
          state.unavailability[existingIndex] = newItem;
        } else {
          state.unavailability.push(newItem);
        }
      });
    })
    .addMatcher(calendarApi.endpoints.deleteCalendarUnavailability.matchFulfilled, (state, { meta }) => {
      state.unavailability = state.unavailability.filter((item) => item.id !== meta.arg.originalArgs.id);
    });
};
