import type { Location } from 'react-router-dom';

/**
 * Helper function to check if a route is active
 * @param location
 * @param path
 * @returns Boolean
 */
export const isRouteActive = (location: Location, path: string) =>
  location.pathname.length === 1 ? path === '/' : location.pathname.startsWith(path) && path !== '/';

/**
 * Helper function to fix the lazy load routes for react-router-dom v6
 * @param load
 * @returns Promise of Component
 * @see https://github.com/remix-run/react-router/discussions/10539
 */
export function lazyRoute<T extends { default: React.ComponentType | null }>(
  load: () => Promise<T>
): () => Promise<Omit<T, 'default'> & { Component: React.ComponentType | null }> {
  return async () => {
    const { default: Component, ...rest } = await load();
    return { ...rest, Component };
  };
}

/**
 * Helper function to generate search params
 * @param params
 * @returns string
 */
export function generateSearchParams(params: Record<string, string | number | null | undefined>): string {
  const searchParams = new URLSearchParams();

  // Loop through each key-value pair in the params object
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key) && params[key] !== undefined && params[key] !== null) {
      // Add the key and value to the search params
      searchParams.append(key, String(params[key]));
    }
  }

  // Return the query string starting with '?'
  return `?${searchParams.toString()}`;
}
