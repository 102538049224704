import { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { NJFormItem } from '@engie-group/fluid-design-system-react';
import { enGB, fr } from 'date-fns/locale';

export type DateRange = [Date | undefined, Date | undefined];

type DateRangePickerProps = {
  // Id of the date picker
  id: string;
  // Label that should be displayed above the date picker
  label?: string;
  // Icon name that should be displayed next to the input
  iconName?: string;
  // Start date of the range
  startDate: Date | undefined;
  // End date of the range
  endDate: Date | undefined;
  // On change function
  onChange: (range: DateRange) => void;
  // Disabled the date range picker
  disabled?: boolean;
  // Required the date range picker
  required?: boolean;
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({ id, label, iconName, required, disabled, startDate, endDate, onChange }) => {
  const { i18n } = useTranslation('common');

  const dateSelectFormat = i18n.language === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';

  return (
    <ReactDatePicker
      locale={i18n.language === 'fr' ? fr : enGB}
      dateFormat={dateSelectFormat}
      customInput={<CustomNJFormItem id={id} label={label} iconName={iconName} isRequired={required} isDisabled={disabled} />}
      calendarClassName="border-1 rounded-0 z-300"
      showPopperArrow={false}
      timeFormat="p"
      disabled={disabled}
      selectsRange
      startDate={startDate}
      endDate={endDate}
      onChange={(date) => onChange([date[0] ?? undefined, date[1] ?? undefined])}
    />
  );
};

type CustomNJFormItemProps = {
  // ID of the form item
  id: string;
  // Label that should be displayed above the form item
  label?: string;
  // Icon name that should be deplay at the end of the input
  iconName?: string;
  // Value that should be displayed in the input
  value?: string;
  // Function that should be called when the input is clicked
  onClick?: () => void;
  // Is the input required
  isRequired?: boolean;
  // Is the input disabled
  isDisabled?: boolean;
};

const CustomNJFormItem = forwardRef<HTMLDivElement, CustomNJFormItemProps>(({ id, label, iconName, value, onClick, isRequired, isDisabled }, ref) => (
  <NJFormItem
    id={id}
    label={label || ''}
    labelKind="static"
    value={value}
    onClick={onClick}
    ref={ref}
    readOnly
    iconName={iconName}
    iconClick={onClick}
    isRequired={isRequired}
    isDisabled={isDisabled}
    className="tw-w-72"
  />
));
